import Head from 'next/head'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import MetaMaskOnboarding from '@metamask/onboarding'
import { setWallet, setUser } from '../redux/auth'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import firebaseApp from '../utils/firebase'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const auth = getAuth(firebaseApp)

const SiteLayout = ({ children, setWallet, setUser, user }) => {
  useEffect(_ => {
    setWallet(MetaMaskOnboarding.isMetaMaskInstalled())
    onAuthStateChanged(auth, user => setUser(JSON.parse(JSON.stringify(user))))
  }, [])

  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1.0, viewport-fit=cover, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no' />
        <meta charSet='utf-8' />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link rel='preconnect' href='https://use.typekit.net' />
        <link href='https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;500;600;700;900' rel='stylesheet' />
        <link rel='stylesheet' href='https://use.typekit.net/qbz2efa.css' />
        <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png'/>
        <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png'/>
        <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png'/>
        <link rel='manifest' href='/manifest.json' />
      </Head>
      {children}
      <ToastContainer
        position='bottom-left'
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  )
}

const ConnectedSiteLayout = connect(({ auth }, ownProps) => ({ ...ownProps, user: auth.user }), { setWallet, setUser })(SiteLayout)
export const getLayout = (page, props) => <ConnectedSiteLayout {...props}>{page}</ConnectedSiteLayout>
export default ConnectedSiteLayout
