import * as Web3 from 'web3'
import { OpenSeaPort, Network } from 'opensea-js'
import AsyncRetry from 'async-retry'
import axios from 'axios'
import detectEthereumProvider from '@metamask/detect-provider'
import { doc, getDoc, getFirestore } from '@firebase/firestore'
import firebaseApp from '../utils/firebase'

export const WETH_CONTRACT_ADDR = process.env.NEXT_PUBLIC_CHAIN === 'mainnet' ? '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2' : '0xc778417e063141139fce010982780140aa0cd5ab'

const INFURA_API = `https://${process.env.NEXT_PUBLIC_CHAIN}.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_KEY}`

const provider = new Web3.providers.HttpProvider(INFURA_API)
const web3 = new Web3(provider)

let seaport
export const getSeaPort = async _ => {
  const ethProvider = await detectEthereumProvider()

  seaport = seaport || new OpenSeaPort(
    ethProvider,
    {
      networkName: process.env.NEXT_PUBLIC_CHAIN === 'mainnet' ? Network.Main : Network.Rinkeby,
      apiKey: process.env.NEXT_PUBLIC_OPENSEA_KEY
    },
    console.info
  )

  return seaport
}

export const getBalance = async addr => {
  const balance = await web3.eth.getBalance(addr) // Will give value in.
  return web3.utils.fromWei(balance)
}

export const getWethBalance = async (seaport, addr) => {
  const balance = await seaport.getTokenBalance({ accountAddress: addr, tokenAddress: WETH_CONTRACT_ADDR })
  return web3.utils.fromWei(balance.toString())
}

let ethPrice
export const getEthPrice = async _ => {
  return (ethPrice ||= await AsyncRetry(_ => window.fetch('https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=ethereum').then(resp => resp.json())))
}

export const fetchOrders = async ({ tokenId, tokenAddress }) => {
  const opts = {
    headers: { 'X-API-KEY': process.env.NEXT_PUBLIC_OPENSEA_KEY }
  }

  const chain = process.env.NEXT_PUBLIC_CHAIN === 'mainnet' ? 'api' : 'rinkeby-api'
  const apiUrl = `https://${chain}.opensea.io/wyvern/v1/orders?asset_contract_address=${tokenAddress}&bundled=false&side=1&include_bundled=false&include_invalid=false&token_id=${tokenId}&limit=20&offset=0&order_by=created_date&order_direction=desc`
  const { data } = await AsyncRetry(_ => axios.get(apiUrl, opts))
  return data.orders
}

export const fetchAsset = async ({ tokenId, tokenAddress }) => {
  const opts = {
    headers: { 'X-API-KEY': process.env.NEXT_PUBLIC_OPENSEA_KEY }
  }

  // Using the rest api as the default js API doesn't seem to return all data
  const chain = process.env.NEXT_PUBLIC_CHAIN === 'mainnet' ? 'api' : 'rinkeby-api'
  const apiUrl = `https://${chain}.opensea.io/api/v1/asset/${tokenAddress}/${tokenId}`
  const { data: asset } = await AsyncRetry(_ => axios.get(apiUrl, opts))

  // const ethPrice = await getEthPrice()

  // Owner seems to be invalid, so overwride with the top_ownerships value
  asset.owner = asset.top_ownerships[0].owner
  asset.tokenAddress = tokenAddress
  asset.tokenId = tokenId

  const orders = await fetchOrders({ tokenId, tokenAddress })

  // Override orders
  asset.saleOrders = orders

  if (asset.lastSale && asset.lastSale.totalPrice) {
    asset.lastSale.totalPriceDollars = asset.lastSale.totalPrice * ethPrice[0].current_price
  }

  // Check if creator has a Crypt account
  const db = getFirestore(firebaseApp)
  const creatorQuery = await getDoc(doc(db, 'users', asset.creator.address))
  const cryptCreator = creatorQuery.data()
  if (cryptCreator) asset.creator.user = { ...asset.creator.user, username: cryptCreator.username }

  // Check if owner has a Crypt account
  const ownerQuery = await getDoc(doc(db, 'users', asset.owner.address))
  const cryptOwner = ownerQuery.data()
  if (cryptOwner) asset.owner.user = { ...asset.owner.user, username: cryptOwner.username }

  // Make response serializeable
  const safeAsset = JSON.parse(JSON.stringify(asset))

  return safeAsset
}
