import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getSeaPort } from '../hooks/nft'
import { OrderSide } from 'opensea-js/lib/types'
import AsyncRetry from 'async-retry'
import { mutate } from 'swr'
import { doc, getDoc, getFirestore } from '@firebase/firestore'
import firebaseApp from '../utils/firebase'
import { toast } from 'react-toastify'
// import { ASSETS as FlashBackAssets } from '../pages/exhibitions/flashback/[[...asset]]'

const AFFILIATES = [
  '0x829A461D13645578319520Bd3134b12070b26E70',
  '0xf6bA8e128e4b284adEc951F3cdFf84D68e0C8878',
  '0x5BA61A8B6644Ce4C40d588dA8264136acc3DAe5F',
  '0x36D28aAA92DA1FeaebA0D5C38e6E98d9Da987A8a',
  '0xbe8f38619A091c3911f44346710a288d77f5a181'
]

const toastOptions = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

// Connect to Metamask
export const buy = createAsyncThunk('assets/buy', async (asset, { getState, dispatch, rejectWithValue }) => {
  const { account } = getState().auth

  if (!account) throw new Error('Account must be specified')

  const seaport = await getSeaPort()
  const { orders } = await AsyncRetry(_ => seaport.api.getOrders({
    asset_contract_address: asset.tokenAddress,
    token_id: asset.tokenId,
    side: OrderSide.Sell
  }))

  const referrerAddress = AFFILIATES[Math.floor(Math.random() * AFFILIATES.length)]
  
  const order = await seaport.fulfillOrder({ 
    order: orders[0], 
    accountAddress: account,
    referrerAddress
  })

  if(order) toast(`🦄 Purchase Successful! You're now the proud owner of '${asset.name}'!`)

  // Get new owners name if available
  const db = getFirestore(firebaseApp)
  const ownerQuery = await getDoc(doc(db, 'users', account))
  const cryptOwner = ownerQuery.data()
  const owner = { address: account }
  if (cryptOwner) owner.user = { username: cryptOwner.username || 'Unknown' }

  return { order, asset, account, owner }
})

// Authenticate
export const sell = createAsyncThunk('assets/sell', async ({ asset, price, days = 21 }, { getState, dispatch }) => {
  const seaport = await getSeaPort()
  const { account } = getState().auth
  const oneDay = 60 * 60 * 24
  const expirationTime = Math.round(Date.now() / 1000 + (oneDay * days))

  const listing = await seaport.createSellOrder({
    asset: {
      tokenId: asset.tokenId,
      tokenAddress: asset.tokenAddress,
      schemaName: 'ERC1155'
    },
    extraBountyBasisPoints: 1.5 * 100,
    accountAddress: account,
    startAmount: price,
    expirationTime
  })

  // get a numberic representation of the closing date
  if (listing) {
    listing.closing_date = new Date(Number(listing.expirationTime.toString()) * 1000)
    listing.current_price = listing.currentPrice
  }

  const safeListing = JSON.parse(JSON.stringify(listing))
  return { listing: safeListing, account, asset }
})

export const cancelSell = createAsyncThunk('assets/sell/cancel', async (asset, { getState, dispatch }) => {
  const { account } = getState().auth
  const seaport = await getSeaPort()
  const { orders } = await AsyncRetry(_ => seaport.api.getOrders({
    asset_contract_address: asset.tokenAddress,
    token_id: asset.tokenId,
    side: OrderSide.Sell
  }))

  await seaport.cancelOrder({ order: orders[0], accountAddress: account })

  return { account, asset }
})

export const assetsSlice = createSlice({
  name: 'assets',
  initialState: {
    // processing: false,
    // hasPurchaseError: false
    // ...FlashBackAssets
  },
  reducers: {
    preventFurtherProcessing (state) {
      state.processing = false
    },
    resetPurchaseError (state) {
      state.hasPurchaseError = false
    }
  },
  extraReducers: {
    [buy.fulfilled]: (state, action) => {
      const { order, asset, account, owner } = action.payload
      // Mutate our local version of the asset with no sale order, and validate after
      if (state.processing) mutate([asset.tokenId, asset.tokenAddress], { ...asset, saleOrders: [order], owner })
      state.processing = false
      state.hasPurchaseError = false
      // toast.success("🥳 You're sale has been processed", toastOptions)
    },
    [sell.fulfilled]: (state, action) => {
      const { account, listing, asset } = action.payload
      // Mutate our local version of the asset with the listing, and validate after
      console.log('listing', listing)
      if (state.processing) mutate([asset.tokenId, asset.tokenAddress], { ...asset, saleOrders: [listing] })
      state.processing = false
      state.hasPurchaseError = false
      // toast.success("🥳 You're item has been listed", toastOptions)
    },
    [cancelSell.fulfilled]: (state, action) => {
      const { asset, account } = action.payload
      // Mutate our local version of the asset with no sale order, and validate after
      if (state.processing) mutate([asset.tokenId, asset.tokenAddress], { ...asset, saleOrders: [] })
      state.processing = false
      state.hasPurchaseError = false
      // toast.success("You're listing has been cancelled", toastOptions)
    },
    [buy.pending]: (state, action) => {
      state.processing = true
      state.hasPurchaseError = false
    },
    [sell.pending]: (state, action) => {
      state.processing = true
      state.hasPurchaseError = false
    },
    [cancelSell.pending]: (state, action) => {
      state.processing = true
      state.hasPurchaseError = false
    },
    [buy.rejected]: (state, action) => {
      state.processing = false
      state.hasPurchaseError = true
      // toast.error(`😖 Oops! ${action.error.message}`, toastOptions)
    },
    [sell.rejected]: (state, action) => {
      state.processing = false
      state.hasPurchaseError = true
      // toast.error(`😖 Oops! ${action.error.message}`, toastOptions)
    },
    [cancelSell.rejected]: (state, action) => {
      state.processing = false
      state.hasPurchaseError = true
      // toast.error(`😖 Oops! ${action.error.message}`, toastOptions)
    }
  }
})

export const { preventFurtherProcessing, resetPurchaseError } = assetsSlice.actions
export default assetsSlice.reducer
