
import Script from 'next/script'
// import * as snippet from '@segment/snippet'

// const renderSnippet = _ => {
//   const opts = {
//     apiKey: process.env.NEXT_PUBLIC_SEGMENT,
//     // note: the page option only covers SSR tracking.
//     // Page.js is used to track other events using `window.analytics.page()`
//     page: true
//   }

//   if (process.env.NODE_ENV === 'development') {
//     return snippet.max(opts)
//   }

//   return snippet.min(opts)
// }

const Segment = _ => {
  console.log('Installing Segment')
  return window && !window.analytics && <Script dangerouslySetInnerHTML={{ __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="ZnN07z4roEMAvHxAvUmufeTQekTKasIw";analytics.SNIPPET_VERSION="4.13.2";
  analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_KEY}");
  analytics.page();
  }}();` }} />
}

export const pageview = event => {
  window && window.analytics && window.analytics.page(event)
}

export default Segment
