import '../styles/globals.css'
import { Provider } from 'react-redux'
import store, { persistor } from '../redux/store'
import { memo, useEffect } from 'react'
import { accountChange } from '../redux/auth'
import { getLayout as getDefaultLayout } from '../layouts/SiteLayout'
import { PersistGate } from 'redux-persist/integration/react'
import { ConsentGate, ConfirmicProvider } from '@confirmic/react'
import dynamic from 'next/dynamic'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { pageview as gaPageView } from '../components/Ga4'
import { pageview as segmentPageView } from '../components/Segment'

if (process.env.NEXT_PUBLIC_CHAIN !== 'mainnet') console.warn(`WARNING! YOU ARE ON THE ${'TESTNET'} `) // .white.redBG.bold.style('font-size: 24px;'));

const DynamicSegment = dynamic(() => import('../components/Segment'), { ssr: false })
const DynamicBeacon = dynamic(() => import('../components/ScoutBeacon'), { ssr: false })
const DynamicGA4 = dynamic(() => import('../components/Ga4'), { ssr: false })

const ConsentManager = memo(_ => (
  <ConfirmicProvider key='confirmic' projectId={process.env.NEXT_PUBLIC_CONFIRMIC}>
    <>
      <ConsentGate micropolicy='marketing-analytics'>
        <>
          <DynamicGA4 />
        </>
      </ConsentGate>
    </>
  </ConfirmicProvider>
))

console.info(`%c
___________.__             _________                        __   
\\__    ___/|  |__   ____   \\_   ___ \\_______ ___.__._______/  |_ 
  |    |   |  |  \\_/ __ \\  /    \\  \\/\\_  __ <   |  |\\____ \\   __\\
  |    |   |   Y  \\  ___/  \\     \\____|  | \\/\\___  ||  |_> >  |  
  |____|   |___|  /\\___  >  \\______  /|__|   / ____||   __/|__|  
                \\/     \\/          \\/        \\/     |__|         
                
⚡ From The Virtually Group
🦄 Made by KUVA https://twitter.com/wearekuva
`, `font-family: monospace`)


function MyApp ({ Component, pageProps }) {
  const getLayout = Component.getLayout || getDefaultLayout
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = url => {
      gaPageView(url)
      segmentPageView(url)
    }

    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => router.events.off('routeChangeComplete', handleRouteChange)
  }, [router.events])

  useEffect(_ => {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', accounts => {
        store.dispatch(accountChange(accounts[0]))
      })
    }
  }, [])

  return (
    <>
      <DynamicBeacon />
      <ConsentManager />
      <DynamicSegment />
      <Provider store={store}>
        { process.browser ? (<PersistGate loading={null} persistor={persistor}>
          {getLayout(<Component {...pageProps} />, pageProps)}
        </PersistGate>) : (getLayout(<Component {...pageProps} />, pageProps)) }
      </Provider>
    </>
  )
}

export default MyApp
