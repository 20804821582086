import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAuth, signInWithCustomToken, updateProfile, updateEmail, deleteUser } from 'firebase/auth'
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore'
import MetaMaskOnboarding from '@metamask/onboarding'
import * as Web3 from 'web3'
import axios from 'axios'
import nonce from '../utils/nonce-message'
import firebaseApp from '../utils/firebase'
import SendBird from '../utils/sendbird'
import { leaveGroup } from './groups'
import getSendbird from '../utils/sendbird'
import { toast } from 'react-toastify'
import * as Sentry from "@sentry/browser"

const auth = getAuth(firebaseApp)
const db = getFirestore(firebaseApp)

const authSendBird = async userId => {
  const snapshot = await getDoc(doc(db, `users/${userId}/private/calls`))
  if(snapshot.exists()){
    const accessToken = snapshot.data().token
    const sb = await SendBird()
    await sb.authenticate({ accessToken, userId })
    await sb.connectWebSocket()
    return accessToken
  }
}

// Connect to Metamask
export const connectToMetamask = createAsyncThunk('auth/metamask/connect', async (value, { dispatch }) => {
  if (!MetaMaskOnboarding.isMetaMaskInstalled()) return Promise.reject(new Error('Metamask is not installed'))
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' })
  return accounts[0]
})

// Authenticate
export const authenticate = createAsyncThunk('auth/authenticate', async _ => {
  if (!MetaMaskOnboarding.isMetaMaskInstalled()) return Promise.reject(new Error('Metamask is not installed'))
  const web3 = new Web3(window.web3.currentProvider)
  const [publicAddress] = await window.ethereum.request({ method: 'eth_requestAccounts' })
  const { data: nonceToken } = await axios.get('/api/user/nonce/' + publicAddress)
  const message = web3.utils.utf8ToHex(nonce(nonceToken))
  const signature = await web3.eth.personal.sign(message, publicAddress)
  const { data } = await axios.get(`/api/auth?signature=${signature}&publicAddress=${publicAddress}`)
  const { user } = await signInWithCustomToken(auth, data)

  const accessToken = await authSendBird(user.uid)

  // Return safe user object
  return JSON.parse(JSON.stringify({ ...user, accessToken }))
})

export const register = createAsyncThunk('auth/register', async ({ username, email }, { dispatch }) => {
  await dispatch(authenticate())
  const user = auth.currentUser
  await updateEmail(user, email)
  await updateProfile(user, { displayName: username })
  // await setDoc(doc(db, `users/${user.uid}/private/email`), { email })
  const jwt = await user.getIdToken()
  await axios.post('/api/registeruser', null, { headers: { Authorization: jwt }})
  const accessToken = await authSendBird(user.uid)
  // Return safe user object
  return JSON.parse(JSON.stringify({ ...user, accessToken }))
})

export const setUser = createAsyncThunk('auth/setUser', async (user, { dispatch }) => {
  if(user && user.uid){
    
    // set user deets on 3rd party services
    Sentry.setUser({ id: user.uid, email: user.email, username: user.displayName })
    if (window && window.Beacon) Beacon('identify', { id: user.uid, email: user.email, name: user.displayName })
    if (window && window.analytics){
      analytics.identify(user.uid, {
        name: user.displayName,
        email: user.email
      })
    }
    
    const accessToken = await authSendBird(user.uid)
    return JSON.parse(JSON.stringify({ ...user, accessToken }))
  } else {
    // deauth SB
    const sb = await getSendbird()
    sb.deauthenticate();
    return null
  }
})

export const signOut = createAsyncThunk('auth/signout', async (value, { getState, dispatch }) => {
  const { groups } = getState()

  // If in a group, leave it
  if(groups.group) await dispatch(leaveGroup())

  // deauth SB
  const sb = await getSendbird()
  sb.deauthenticate();
  
  auth.signOut()
})

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isWalletAvailable: false,
    account: null,
    user: 'unknown',
    sessionID: String(Math.floor(Math.random() * 99999999999999))
  },
  reducers: {
    accountChange (state, action) {
      auth.signOut()
      state.user = null
      state.account = action.payload
    },
    // signOut (state) {
    //   auth.signOut()
    //   state.user = null
    //   state.account = null
    // },
    setWallet (state, action) {
      state.isWalletAvailable = action.payload
    },
    // setUser (state, action) {
    //   state.user = action.payload
    //   if (state.user && state.user.uid){
    //     state.account = state.user.uid
    //     authSendBird(state.user.uid)
    //   }
    // }
  },
  extraReducers: {
    [setUser.fulfilled]: (state, action) => {
      state.user = action.payload
      if (state.user && state.user.uid){
        state.account = state.user.uid
      }
    },
    [setUser.rejected]: (state, action) => {
      auth.signOut()
      state.user = null
      state.account = null
    },
    [connectToMetamask.fulfilled]: (state, action) => {
      state.account = action.payload
    },
    [signOut.fulfilled]: (state, action) => {
      state.user = null
      state.account = null
    },
    [authenticate.fulfilled]: (state, action) => {
      state.user = action.payload
    },
    [register.fulfilled]: (state, action) => {
      state.user = action.payload
    },
    [authenticate.rejected]: (state, action) => {
      auth.signOut()
      getSendbird().then(sb => sb && sb.deauthenticate())
      state.user = null
    },
    [register.rejected]: (state, action) => {
      auth.signOut()
      if(auth.currentUser) deleteUser(auth.currentUser)
      getSendbird().then(sb => sb && sb.deauthenticate())
      state.user = null
      if(action.error.message.includes('auth/email-already-in-use') ){
        toast.error('😖 Hmm! It seems that email address is already linked with another account')
      }else{
        toast.error('😖 Hmm! Something went wrong')
      }
    }
  }
})

export const { accountChange, setWallet } = authSlice.actions
export default authSlice.reducer
