import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import auth from './auth'
import site from './site'
import assets from './assets'
import groups from './groups'

const persistConfig = {
  key: 'root',
  version: 1,
  storage
}

const persistedSiteReducer = persistReducer({
  ...persistConfig,
  whitelist: ['hasCompletedOnboarding']
}, site)

const persistedAuthReducer = persistReducer({
  ...persistConfig,
  whitelist: ['account']
}, auth)

const store = configureStore({
  reducer: { assets, groups, auth: persistedAuthReducer, site: persistedSiteReducer },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})

export default store
export const persistor = persistStore(store)
