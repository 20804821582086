const generateNonce = nonce => `Hi there from The Crypt! 😎

Sign this message to prove you have access to this wallet and we’ll log you in. This won’t cost you any Ether.

To stop hackers using your wallet, here’s a unique message ID, but don't worry you don't have to remember it:

${nonce}
`

export default generateNonce
