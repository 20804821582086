let SendBirdCall

// SendBird requires access to window object, which causes issues with Next
// We're using a dynamic import which skips the issue

const getSendbird = async _ => {
  if(SendBirdCall) return SendBirdCall

  const module = await import('sendbird-calls')
  SendBirdCall = module.default
  SendBirdCall.init(process.env.NEXT_PUBLIC_SENDBIRD_APP_ID)

  return SendBirdCall
}

export default getSendbird