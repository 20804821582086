import { createSlice } from '@reduxjs/toolkit'

export const siteSlice = createSlice({
  name: 'site',
  initialState: {
    popoutState: '',
    exploreMode: 'animation',
    hasCompletedOnboarding: false
  },
  reducers: {
    openPopout (state, action) {
      const path = action.payload.page || action.payload
      const valid = ['groups', 'auth', 'onboarding'].includes(path)
      state.popoutState = valid && path
      if(path === 'onboarding') state.exploreMode = 'firstperson'
    },
    closePopout (state, action) {
      state.popoutState = null
    },
    setAnimationMode (state, action) {
      state.exploreMode = 'animation'
    },
    setFirstPersonMode (state, action) {
      if(!state.hasCompletedOnboarding) state.popoutState = 'onboarding'
      state.exploreMode = 'firstperson'
    },
    completeOnboarding (state) {
      state.hasCompletedOnboarding = true
    }
  }
})

export const { showMenu, completeOnboarding, openPopout, closePopout, setFirstPersonMode, setAnimationMode } = siteSlice.actions
export default siteSlice.reducer
